<template>
  <div class="product-review">
    <vs-row v-if="product.productOption.includes('pay-now') && (cardEnabled || product.eftEnabled)" class="mb-8">
      <vs-col vs-w="12" class="flex items-center">
        <div class="flex justify-center items-center" style="max-width: 20px">
          <feather-icon icon="CodepenIcon" class="text-grey-outline" />
        </div>
        <h5 class="ml-5 mb-0">Payments</h5>
      </vs-col>
      <vs-col vs-w="12">
        <hr class="line-hr mt-2 mb-4" />
      </vs-col>

      <vs-col vs-w="12" class="onboarding-form">
        <div class="flex flex-wrap">
          <div class="md:col-start-3 md:col-end-11 bg-container-80">
            <p class="text-body mb-10">Available payment methods and corresponding transaction fees are listed below.</p>
            <div class="flex justify-between">
              <h5 class="w-185">Payment method</h5>
              <h5 class="w-12">Fee</h5>
            </div>

            <hr class="mt-2 mb-6" />

            <div class="grid gap-y-3" v-if="product.payNowOptions.includes('CARDS') && cardEnabled">
              <div class="flex items-center justify-between" :key="index" v-for="(card, index) in product.payNowPaymentMethods">
                <div class="flex items-center gap-x-4 w-200">
                  <img v-if="card.title.toLowerCase() == 'visa'" :src="visaCard" alt="visa" />
                  <img v-else-if="card.title.toLowerCase() == 'mastercard'" :src="masterCard" alt="masterCard" />
                  <img v-else-if="card.title.toLowerCase() == 'american express'" :src="amexCard" alt="amex" />
                  <img v-else-if="card.title.toLowerCase() == 'diners club'" :src="dinersClubCard" alt="diners-club" />
                  <img v-else-if="card.title.toLowerCase() == 'china unionpay'" :src="unionPayCard" alt="unionPay" />
                  <p>{{ card.title }}</p>
                </div>
                <p>{{ `${addZeroes(card.surcharge)} %` }}</p>
              </div>
            </div>

            <div class="grid mt-3" v-if="product.payNowOptions.includes('EFT') && product.eftEnabled">
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-x-4 w-200">
                  <img :src="payId" alt="payId" height="20" width="50" />
                  <p>EFT via {{appName}} virtual account</p>
                </div>
                <p class="w-12">Free</p>
              </div>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>

    <vs-row v-if="product.productOption.includes('pay-later')">
      <vs-col vs-w="12" class="flex items-center">
        <feather-icon icon="CodepenIcon" class="text-grey-outline"/>
        <h5 class="pl-4 lg:text-xl md:text-lg text-sm mb-0 font-normal">Finance options</h5>
      </vs-col>
      <vs-col vs-w="12">
        <hr class="line-hr mt-2 mb-4" />
      </vs-col>

      <vs-col vs-w="12" class="onboarding-form">
        <vs-row class="grid grid-cols-3 paymentOpt mb-8 pr-8" style="position: relative">
          <vs-col v-for="(payLaterPlan, key) in product.payLaterProducts" :key="key">
            <div class="radio-card w-auto" v-if="payLaterPlan.isChecked">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label review">
                  {{ payLaterPlan.productName }}
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div>
                    <span class="flex items-center">
                      <p class="text-xs">Service fee:</p>
                      <h6 class="ml-2 text-xs">{{ addZeroes(parseFloat(payLaterPlan.msf)) }} %</h6>
                      <span class="text-xs ml-1">{{ payLaterPlan.feeModel == "MSF_INCLUDED" ? " (payable by you)" : " (payable by customer)" }}</span>
                    </span>
                    <span class="flex items-center" v-if="payLaterPlan.minimumFeeAmount > 0">
                      <p class="text-xs">Minimum fee:</p>
                      <h6 class="ml-2 text-xs">$ {{ addZeroes(parseFloat(payLaterPlan.minimumFeeAmount)) }}</h6>
                    </span>

                    <span class="flex items-center" v-if="payLaterPlan.initialPeriod">
                      <p class="text-xs">Period:</p>
                      <h6 class="ml-2 text-xs">{{ payLaterPlan.initialPeriod }} days</h6>
                    </span>

                    <span class="flex items-center" v-if="payLaterPlan.noOfPayments">
                      <p class="text-xs">Schedule:</p>
                      <h6 class="ml-2 text-xs">{{ `${payLaterPlan.noOfPayments} payments, ${payLaterPlan.paymentFrequency.toLowerCase()}` }}</h6>
                    </span>

                    <span class="flex items-center">
                      <p class="text-xs">Limit per request:</p>
                      <h6 class="ml-2 text-xs">$ {{ addZeroes(parseFloat(payLaterPlan.maximumAmount)) }}</h6>
                    </span>

                    <span class="flex items-center mb-6">
                      <p class="text-xs"><a class="text-base font-light" @click="handleTermsClick(payLaterPlan.customPlanId)">Terms & conditions</a></p>
                    </span>

                    <template v-if="payLaterPlan.banks && payLaterPlan.banks.length > 0 && !(payLaterPlan.productConfigType === 'THREE_PARTY' && payLaterPlan.disburseTo == 'external')">
                      <p class="mt-2">Linked bank accounts</p>
                      <template v-for="(bank, index) in getBanks(payLaterPlan.banks)">
                        <div :key="index" class="mt-2">
                          <span class="font-bold">Bank {{ index+ 1 }}</span><br>
                          <span>Account name:</span> <span>{{ bank.accountName }}</span><br>
                          <span>Bsb:</span> <span>{{ bank.bsb }}</span><br>
                          <span>Account number:</span> <span>{{ bank.accountNumber }}</span><br>
                        </div>
                      </template>
                    </template>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
          <div style="position: absolute; top: 18px; right: 0px">
            <span v-if="showEditButton">
              <edit-2-icon @click="goToEditPaylater()"></edit-2-icon>
            </span>
          </div>
        </vs-row>

      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { Edit2Icon } from "vue-feather-icons";
import S3ImageGenerator from "@/views/onboarding/components/S3ImageGenerator";
const payId = require("@/assets/images/cards/payid.svg");
const amexCard = require("@/assets/images/cards/amex.svg");
const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
const directDebitCard = require("@/assets/images/cards/directdebit.svg");
const masterCard = require("@/assets/images/cards/mastercard.svg");
const unionPayCard = require("@/assets/images/cards/unionpay.svg");
const visaCard = require("@/assets/images/cards/visa.svg");

export default {
  components: {
    Edit2Icon,
    S3ImageGenerator
  },
  name: "ProductSelection",
  data() {
    return {
      appName: process.env.VUE_APP_NAME == "FlipPay" ? "Flip" : "Rello",
      uploadedUrl: process.env.VUE_APP_API_URL + "uploads/icons/",
      payId: payId,
      cardEnabled: false,
      amexCard: amexCard,
      dinersClubCard: dinersClubCard,
      directDebitCard: directDebitCard,
      masterCard: masterCard,
      unionPayCard: unionPayCard,
      visaCard: visaCard,
    };
  },
  props: {
    banks: { type: Array },
    product: { type: Object },
    showEditButton: { type: Boolean },
    userId: { type: String },
  },
  methods: {
    handleTermsClick(planId) {
      window.open(`${process.env.VUE_APP_ADMIN_URL}products/${planId}/borrower`, "_blank");
    },

    addZeroes(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    goToEdit() {
      this.$router.push({
        name: "onboarding-form",
        params: {
          introducerRequestedId: this.userId,
          card: "productSelection",
        },
        query: {
          scrollTo: "productSelectionArea",
        },
      });
    },

    goToEditPaylater() {
      this.$router.push({
        name: "onboarding-form",
        params: {
          introducerRequestedId: this.userId,
          card: "productSelection",
        },
        query: {
          scrollTo: "paylaterArea",
        },
      });
    },

    goToEditPayNow() {
      this.$router.push({
        name: "onboarding-form",
        params: {
          introducerRequestedId: this.userId,
          card: "productSelection",
        },
        query: {
          scrollTo: "payNowArea",
        },
      });
    },

    getBanks(bsb) {
      if (!bsb || !bsb.length) {
        return [];
      }

      let banks = [];

      bsb.forEach(item => {
        const filter = item.split("/");

        const bank = this.banks.find(element => {
          if (filter && filter.length && filter.length > 1) {
            return (element.bsb == filter[0] && element.accountNumber == filter[1] && element.accountName == filter[2]);
          } else {
            return element.bsb == filter[0];
          }
        });

        if (bank) {
          banks.push(bank);
        }
      });

      return banks;
    }
  },
  mounted() {
    this.cardEnabled = this.product.payNowPaymentMethods.some((item) => item.isChecked);
  }
};
</script>